import React, {useState, useEffect} from 'react';
import api from './services/axios';

const RightSidebar = ({
                          serverIp,
                          setView,
                          slots,
                          screen,
                          liveFeedWS,
                          activeChannel,
                          setLiveFeedTimestamp,
                          setLiveFeedStatus,
                          setStartTime,
                          setInitialSlotIndex,
                          setInitialImageIndex,
    changeServer
                      }) => {
    const WS_URL = process.env?.REACT_APP_WEBSOCKET_URL || 'ws://localhost:3001'; // WebSocket URL
    const [liveFeed, setLiveFeed] = useState({
        slot: null,
        image: null,
        time: null,
        imageUrl: '',
    });
    const [lastUpdateTime, setLastUpdateTime] = useState(null);
    const [debouncedRequest, setDebouncedRequest] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [websocket, setWebsocket] = useState(null);
    const [channelActivate, setActiveChannel] = useState(null);
    const [token, setToken] = useState(null);
    const [feed, setFeed] = useState(null);
    const login = async () => {
        try {
            const response = await api.post('/api/user/ws-token');
            const jwtToken = response.data.token;
            setToken(jwtToken);
            console.log("Logged in successfully, received token:", jwtToken);
            return jwtToken;
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    const copyToClipboard = () => {
        const jsonConfig = JSON.stringify(slots, null, 2);
        navigator.clipboard
            .writeText(jsonConfig)
            .then(() => alert('Configuration copied to clipboard!'))
            .catch((err) => alert('Failed to copy: ', err));
    };

    const exportToFile = () => {
        const jsonConfig = JSON.stringify(slots, null, 2);
        const blob = new Blob([jsonConfig], {type: 'application/json'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'config.json';
        a.click();
        URL.revokeObjectURL(url);
    };

    const uploadConfig = async () => {
        const jsonConfig = JSON.stringify(slots, null, 2);
        try {
            const response = await api.post('/api/device/update-config',
              {
                  channel: activeChannel,
                  newConfig: jsonConfig
              }
            );
            if(response.status === 200) {
                alert('File uploaded successfully');
            } else {
                alert('Failed to upload file: ' + response);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file');
        }
    };

    return (
        <div id='right-sidebar'>
            <div className='right-sidebar-content'>
                <pre className='log-content'>{JSON.stringify(slots, null, 2)}</pre>
                <button onClick={copyToClipboard}>Copy to Clipboard</button>
                <button onClick={exportToFile}>Export to File</button>
                <button onClick={uploadConfig}>Upload Config</button>
            </div>
            <div className='right-sidebar-live-feed'>
                <h3>Live Feed</h3>
                {(isPaused || liveFeedWS === null) ? (
                    <p>Waiting for updates...</p>
                ) : (
                    <>
                        <p>Last feed at {liveFeedWS.time}</p>
                        <p>Current slot: {liveFeedWS.slot}</p>
                        <p>current image: {liveFeedWS.image}</p>
                        {liveFeedWS.imageUrl && (
                            <img
                                src={liveFeedWS.imageUrl}
                                alt='Current'
                                style={{maxWidth: '100%'}}
                            />
                        )}
                    </>
                )}
            </div>
            <div className='server-settings'>
                <h3>Node details</h3>
                <p>Last Connection: {new Date(screen.lastConnection).toLocaleString() || "Never"}</p>
                <p>Current IP: {serverIp}</p>
                <button onClick={() => changeServer(activeChannel)}>Change Server</button>
            </div>
        </div>
    );
};

export default RightSidebar;
